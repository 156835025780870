<div id="pcoded" class="pcoded"
     [attr.nav-type]="navType"
     [attr.theme-layout]="themeLayout"
     [attr.vertical-placement]="verticalPlacement"
     [attr.vertical-layout]="verticalLayout"
     [attr.pcoded-device-type]="pcodedDeviceType"
     [attr.vertical-nav-type]="verticalNavType"
     [attr.vertical-effect]="verticalEffect"
     [attr.vnavigation-view]="vnavigationView"
     [attr.fream-type]="freamType"
     [attr.sidebar-img]="sidebarImg"
     [attr.sidebar-img-type]="sidebarImgType"
     [attr.layout-type]="layoutType"
     (window:resize)="onResize($event)"
  >
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header" [attr.header-theme]="headerTheme" [attr.pcoded-header-position]="pcodedHeaderPosition">
      <div class="navbar-wrapper">
        <div class="navbar-logo">
          <a class="mobile-menu" id="mobile-collapse" href="javascript:" (click)="toggleOpened()" [exclude]="'#main_navbar'" (clickOutside)="onClickedOutsideSidebar($event)">
            <i class="ti-menu"></i>
          </a>
          <a [routerLink]="['/dashboard/default/']">
            <img class="img-fluid" src="assets/images/50x50_Finvu_Logo_Circle.png" alt="Theme-Logo" style="width:43.028px;height:36.028px"/>
            <span style="font-size: 10px;padding-left: 5px;text-transform: capitalize;font-weight: bold;"><br>Finances Made Simple</span>
          </a>
          <a href="javascript:" class="mobile-options" (click)="toggleHeaderNavRight()">
            <i class="ti-more"></i>
          </a>
        </div>
        <div class="navbar-container container-fluid">
          <!-- <ul class="nav-left">
            <li>
              <div class="sidebar_toggle"><a href="javascript:"><i class="ti-menu"></i></a></div>
            </li>
            <li class="header-search">
              <div id="main-search" class="main-search morphsearch-search">
                <div class="input-group">
                  <span class="input-group-prepend search-close" (click)="searchOff()"><i class="ti-close input-group-text"></i></span>
                  <input class="form-control" [style.width]="searchWidthString">
                  <span class="input-group-append search-btn" (click)="searchOn()"><i class="ti-search input-group-text"></i></span>
                </div>
              </div>
            </li>
            <li appToggleFullScreen>
              <a href="javascript:">
                <i class="ti-fullscreen"></i>
              </a>
            </li>
          </ul> -->
          <ul [@mobileHeaderNavRight]="navRight" [ngClass]="navRight" class="nav-right">
            <li (clickOutside)="notificationOutsideClick('profile')" class="user-profile header-notification" [ngClass]="profileNotificationClass" (click)="toggleProfileNotification()">
              <a href="javascript:">
                <img src="assets/images/user.svg" class="img-radius" alt="User-Profile-Image">
                <span>{{ currentUser }} {{ userRole }}</span>
                <i class="ti-angle-down" style="font-weight: bold;"></i>
              </a>
              <ul [@notificationBottom]="profileNotification" class="show-notification profile-notification">
                <!-- <li>
                  <a href="javascript:">
                    <i class="ti-settings"></i> Settings
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/user/profile/']">
                    <i class="ti-user"></i> Profile
                  </a>
                </li> -->
                <li>
                  <a [routerLink]="['/logout']">
                    <i class="ti-layout-sidebar-left"></i> Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>

        </div>
      </div>
    </nav>
    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <div class="pcoded-wrapper">
        <nav
          id="main_navbar"
          class="pcoded-navbar"
          (clickOutside)="onClickedOutsideSidebar($event)"
          [exclude]="'#mobile-collapse'"
          [attr.pcoded-header-position]="pcodedHeaderPosition"
          [attr.navbar-theme]="navBarTheme"
          [attr.active-item-theme]="activeItemTheme"
          sub-item-theme="theme2"
          active-item-style="style0"
          [attr.pcoded-navbar-position]="pcodedSidebarPosition"
        >
          <div class="pcoded-inner-navbar main-menu" appAccordion>
            <perfect-scrollbar [style.max-width]="'235px'" [style.max-height]="sidebarFixedHeight" [config]="config">
              <span *ngFor="let asideItems of menuItems.getAll()" class="d-color">
                <div class="pcoded-navigation-label" [attr.menu-title-theme]="menuTitleTheme">{{asideItems.label}}</div>
                <ul class="pcoded-item pcoded-left-item" [attr.item-border]="itemBorder" [attr.item-border-style]="itemBorderStyle" [attr.subitem-border]="subItemBorder" *ngFor="let asideItem of asideItems.main" appAccordionLink group="{{asideItem.state}}">
                  <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'external'" appAccordionLink group="{{asideItem.state}}">
                    <a href="{{asideItem.external}}" target="{{asideItem.target ? '_blank' : '_self'}}" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                  </li>

                  <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'link'" appAccordionLink group="{{asideItem.state}}">
                    <a [routerLink]="['/', asideItem.main_state, asideItem.state]" target="{{asideItem.target ? '_blank' : '_self'}}" appAccordionToggle *ngIf="asideItem.main_state; else mainContent">
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ng-template #mainContent>
                      <a [routerLink]="['/', asideItem.state]" target="{{asideItem.target ? '_blank' : '_self'}}" appAccordionToggle>
                        <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                        <span class="pcoded-mtext">{{ asideItem.name }}</span>
                        <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                        <span class="pcoded-mcaret"></span>
                      </a>
                    </ng-template>
                  </li>
                  <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'sub'" class="pcoded-hasmenu" [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" appAccordionLink group="{{asideItem.state}}">
                    <a [routerLinkActive]="['active']" href="javascript:;" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ul class="pcoded-submenu">
                      <ng-template ngFor let-asideChildren [ngForOf]="asideItem.children">
                        <li [routerLinkActive]="['active']" *ngIf="asideChildren.type !== 'sub'">
                          <a [routerLink]="['/', asideItem.state, asideChildren.state]" target="{{asideChildren.target ? '_blank' : '_self'}}">
                            <span class="pcoded-micon"><i class="ti-angle-right"></i></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }} </span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{asideChildrenBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                        </li>

                        <li [routerLinkActive]="['active']" class="pcoded-hasmenu" [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" *ngIf="asideChildren.type === 'sub'" appAccordionLink group="sub-toggled">
                          <a href="javascript:;" appAccordionToggle>
                            <span class="pcoded-micon"><i class="ti-direction-alt"></i></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{asideChildrenBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                          <ul class="pcoded-submenu">
                            <ng-template ngFor let-asideChildrenSub [ngForOf]="asideChildren.children">
                              <li [routerLinkActive]="['active']">
                                <a [routerLink]="['/', asideItem.state, asideChildren.state, asideChildrenSub.state]" target="{{asideChildrenSub.target ? '_blank' : '_self'}}">
                                  <span class="pcoded-micon"><i class="ti-angle-right"></i></span>
                                  <span class="pcoded-mtext">{{ asideChildrenSub.name }}</span>
                                  <span *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge" class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}">{{asideChildrenSubBadge.value}}</span>
                                  <span class="pcoded-mcaret"></span>
                                </a>
                              </li>
                            </ng-template>
                          </ul>
                        </li>
                      </ng-template>
                    </ul>
                  </li>
                </ul>
              </span>
            </perfect-scrollbar>
          </div>
        </nav>
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <!-- Main-body start -->
            <div class="main-body">
              <div class="page-wrapper">
                <router-outlet>
                  <app-title></app-title>
                  <app-breadcrumbs></app-breadcrumbs>
                  <app-spinner></app-spinner>
                </router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
