import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AuthGuard } from './modules/guard/auth.guard';
import { LogoutComponent } from './modules/auth/logout/logout.component';
import { Role } from './services/user-login/role';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'consent-search',
        loadChildren: './modules/search/consent-search/consent-search.module#ConsentSearchModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'customer-search',
        loadChildren: './modules/search/customer-search/customer-search.module#CustomerSearchModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'aa-management',
        loadChildren: './modules/aa-management/aa-management.module#AAManagementModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'access-management',
        loadChildren: './modules/access-management/access-management.module#AccessManagementModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'fi-requests-search',
        loadChildren: './modules/search/fi-request-search/fi-request-search.module#FiRequestSearchModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'fip-configuration',
        loadChildren: './modules/fip-configuration/fip-configuration.module#FIPConfigurationModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      },
      {
        path: 'consent-insight',
        loadChildren: './modules/investment/consent-insight/consent-insight.module#ConsentInsightModule',
        canActivate: [AuthGuard],
        data: { userRole: [Role.Admin] }
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: './modules/auth/login/basic-login.module#BasicLoginModule'
      },
      {
        path: 'logout',
        component: LogoutComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
