import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: '',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home',
      }
    ]
  },
  {
    label: 'Search',
    main: [
      {
        state: 'customer-search',
        short_label: 'C',
        name: 'Customer',
        type: 'link',
        icon: 'ti-search'
      },
      {
        state: 'consent-search',
        short_label: 'C',
        name: 'Consent',
        type: 'link',
        icon: 'ti-search'
      }
    ]
  },
  {
    label: 'Customer',
    main: [
      // {
      //   state: 'consent-revoke',
      //   short_label: 'C',
      //   name: 'Consent Revoke',
      //   type: 'link',
      //   icon: 'ti-stamp'
      // },
      {
        state: 'customer-activity',
        short_label: 'C',
        name: 'Customer Activity',
        type: 'link',
        icon: 'ti-user'
      }
    ]
  },
  {
    label: 'Admin Functions',
    main: [
      {
        state: 'access-management',
        short_label: 'A',
        name: 'Access Management',
        type: 'sub',
        icon: 'ti-id-badge',
        children: [
          {
            state: 'manage-roles',
            name: 'Manage Roles',
            icon: 'ti-id-badge'
          },
          {
            state: 'manage-users',
            name: 'Manage Users',
            icon: 'ti-user'
          }
        ]
      },
      {
        state: 'fip-configuration',
        short_label: 'F',
        name: 'FIP Configuration',
        type: 'link',
        icon: 'ti-settings'
      },
      {
        state: 'aa-management',
        short_label: 'A',
        name: 'AA Management',
        type: 'sub',
        icon: 'ti-agenda',
        children: [
          {
            state: 'configure-aa',
            name: 'Configure AA	'
          },
          {
            state: 'enable-disable-aa',
            name: 'Enable/Disable AA'
          }
        ]
      }
    ]
  },
  {
    label: 'Reports',
    main: [
      {
        state: 'generate',
        short_label: 'G',
        name: 'Generate',
        type: 'link',
        icon: 'ti-receipt'
      },
      {
        state: 'management',
        short_label: 'M',
        name: 'Management',
        type: 'link',
        icon: 'ti-agenda'
      }
    ]
  }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
