import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { ConfigService } from '../config/config-service';
import { UserLogin } from './user-login';
import { Observable , throwError } from 'rxjs';
import { WebHeader } from '../web-header';
import { WebRequest } from '../web-request';
import { retry, catchError } from 'rxjs/operators';
import { WebResponse } from '../web-response';

@Injectable({
  providedIn: 'root'
})

export class UserLoginService {

  apiBaseUrl: string;

  constructor(private http: HttpClient,
    public configService: ConfigService) {
      this.apiBaseUrl = this.configService.apiBaseUrl;
  }

  userLogin(userLogin:UserLogin): Observable<UserLogin> {
    const header: WebHeader = {
      rid: localStorage.getItem("messageID"),
      ts: new Date()
    };

    const request: WebRequest = {
      header: header,
      body: userLogin
    };
    return this.http.post<UserLogin>(this.apiBaseUrl +'/user/login', request).pipe (retry(1),
    catchError(this.handleError));
  }

  userPermissions() {
    var response = this.http.get<WebResponse>(this.apiBaseUrl +'/permissions');
    response.map((res: WebResponse) => res.header).subscribe(data =>{
      var header = [];
      header = Object.keys(data).map(e => data[e]);
      localStorage.setItem("messageID", header[0]);
    })
    return response.map((res: WebResponse) => res.body).pipe(retry(1),
    catchError(this.handlePermissionError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.status == 401){
        window.alert("Invalid credentials");
      } else if(error.status == 500){
        window.alert("Account not found for user "+ sessionStorage.getItem("currentUser"));
      }
    }
    return throwError(errorMessage);
  }

  handlePermissionError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.status == 500){
      window.alert("Invalid credentials");
    } else if(error.status == 401) {
      window.alert("User does not have permission");
    } else if(error.status == 400){
      window.alert("Something went wrong!");
    }
    return throwError(errorMessage);
  }
}
