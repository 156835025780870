﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, 
         RouterStateSnapshot } from '@angular/router';
 
@Injectable()
export class AuthGuard implements CanActivate {

    permissions:any = [];
    returnUrl: string;
    
    constructor( private router: Router ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       
        console.log("Route data is: " + route.data.userRole);
        console.log("User Role is: " + sessionStorage.getItem("userRole"));
       
        if(sessionStorage.getItem('currentUser')) {
            if( route.data.userRole && route.data.userRole == sessionStorage.getItem("userRole")) {
                return true;
            }
        } 
        // not logged in so redirect to login page with the return url
        sessionStorage.clear();
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }  
}