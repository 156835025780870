import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.logout();
  }

  logout(){
    console.info('logout called');
    // reset login status
    //this.authenticationService.logout();
    localStorage.removeItem('currentUser');

    this.router.navigate(['/login']);
  }

}
